import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const liminaire = () => (
  <Layout>
    <SEO title="Dernières oeillades - Étonnement" />
    <h3 className='underline-title'>Étonnement</h3>
    <br />
    <br />
    <div className="table-responsive">
      <p>
        Je vois la fureur sans cause de mon cousin<br />
        Et la frénésie sans affection de mon oncle<br />
        Quel est le moteur de ce branle-bas du soir?<br />
        Ils voient pourtant que je m'en vais au champ<br />
        Ils savent pourtant que je pousse une brouette<br />
        Brouette remplie de fumier pour mon jardin<br />
        Fichtre! Ils me demandent de concourir!<br />
        Ma brouette jardinière contre un mig supersonique!<br />
        Ma brouette encore face aux déflagrantions d'um MX!<br />
      </p>
      <p>
        Un fantasme d'hallucinés?
      </p>
    </div>
    <br />
    <br />
    <Link to="/dernieres-oeillades-table-des-matieres">Table des matières</Link>
  </Layout>
)

export default liminaire
